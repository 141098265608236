var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table_wrapper"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.desserts,"sort-by":"c1","loading":_vm.loading,"loading-text":"Loading... Please wait","search":_vm.search,"items-per-page":_vm.perPage,"options":_vm.options,"server-items-length":_vm.totalDesserts},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.c5",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"warning","to":'/faq/edit/' + item.item.id,"fab":"","small":"","elevation":"2"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"error","fab":"","small":"","elevation":"2"},on:{"click":function($event){return _vm.deletItem(item.item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("FAQ")]),(_vm.selected.length > 0)?_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","color":"dark","dark":""},on:{"click":_vm.deleteSelected}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-close ")]),_vm._v(" Delete Selected ("+_vm._s(_vm.selected.length)+") ")],1):_vm._e(),_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","color":"primary","dark":"","to":'/faq/new'}},[_vm._v(" New ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","disabled":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }